export const ROUTES = {
  WHY_HOLADEV   : '/why_holadev',
  OUR_EXPERTISE : '/our_expertise',
  ABOUT_US      : '/about_us',
  CAREERS       : '/careers',
  INSIGHTS      : '/insights',
  CONTACT       : '/contact',
  DEVS4STARTUPS : 'https://devs4startups.com'
};


export const navigation = [
  { name : 'Why HolaDev', href : ROUTES.WHY_HOLADEV },
  { name : 'Our Expertise', href : ROUTES.OUR_EXPERTISE },
  { name : 'About us', href : ROUTES.ABOUT_US },
  { name : 'Careers', href : ROUTES.CAREERS },
  { name : 'Our Insights', href : ROUTES.INSIGHTS },
  { name : 'Get Started', href : ROUTES.CONTACT, type : 'button' }
];
