import React from 'react';
import { navigation } from './constants';
import Logo from '../../images/svg/logo.svg';
import { Link } from 'gatsby';
import { ReactComponent as LinkedinIcon } from '../../images/svg/linkedin.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const socialAccounts = [{
    name        : 'linkedin',
    renderIcon  : className => (<LinkedinIcon className={`fill-linkedIn ${className}`} />),
    profileName : 'HolaDev',
    url         : 'https://www.linkedin.com/company/holadev/'
  }];

  return (
    <footer className="max-w-screen-large mx-auto mb-12 bg-white border-t-2 border-neutral pt-10 px-12">
      <div className="flex flex-col-reverse sm:flex-row justify-between text-black text-sm font-normal mb-10">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-semibold mb-2 text-center sm:text-left mt-14 sm:mt-0">Sitemap</p>
          <div className="flex flex-row sm:flex-col justify-center sm:justify-start gap-3 flex-wrap">
            {
              navigation.map( item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className='no-decorate'
                >

                  {item.name}
                </Link>
              ))
            }
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-lg font-semibold mb-2 text-center sm:text-left mt-14 sm:mt-0">Follow us</p>
          <div className="flex flex-row sm:flex-col justify-center sm:justify-start gap-3 flex-wrap pb-0 sm:pb-12">
            {
              socialAccounts.map( item => (
                <a
                  key={item.name}
                  href={item.url}
                  target="_blank"
                  className="flex items-center pr-2 no-decorate"
                  rel="noreferrer"
                >
                  {item.renderIcon('w-6 mr-2')}
                  {item.profileName}
                </a>
              ))
            }
          </div>
          <p className="text-lg font-semibold mb-2 text-center sm:text-left mt-14 sm:mt-0">Email us</p>
          <div className="flex flex-row sm:flex-col justify-center sm:justify-start gap-3 flex-wrap">
            <a className="no-decorate" href="mailto:contact@holadev.io">contact@holadev.io</a>
          </div>
        </div>
        <div className="flex self-center sm:self-start justify-end flex-col items-end">
          <Link to="/" className="self-end"><img className="max-w-[220px]" src={Logo} alt="HolaDev"/></Link>
        </div>
      </div>
      <span className="border-t border-solid border-slate-400 w-full block opacity-50"></span>
      <div className="flex flex-col md:flex-row md:justify-between text-black text-sm font-medium pt-4">
        <div className="flex text-center md:text-left max-w-[380px] items-center justify-between self-center md:self-start">
          <Link to="/privacy-policy" className="pr-2 no-decorate">Privacy Policy </Link>
          -
          <Link to="/terms-and-conditions" className="px-2 no-decorate"> Terms & Conditions </Link>
          -
          <Link to="/cookies-policy" className="pl-2 no-decorate"> Cookies Policy</Link>
        </div>
        <div className="flex text-center md:text-right self-center md:self-end mt-4 md:mt-0">
          © { currentYear } HolaDev
        </div>
      </div>
    </footer>
  );
};

export default Footer;
