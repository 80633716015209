import React from 'react';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';

// eslint-disable-next-line react/prop-types
const Layout = ({ location, children }) => {
  return (
    <>
      <Navbar location={location} />
      <div className="w-full pt-20">
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
