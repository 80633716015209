/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'gatsby';
import { Disclosure, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../../images/svg/logo.svg';
import { navigation } from './constants';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = ({ location }) => {
  const isCurrentPathActive = path => location.pathname === path;

  return (
    <Disclosure as="nav" className="fixed top-0 left-0 right-0 bg-white holadev-navbar z-20">
      {({ open }) => (
        <>
          <div className="max-w-screen-large mx-auto sm:px-5 md:px-6 lg:px-6 min-[1380px]:px-12">
            <div className="relative flex items-center justify-between h-24">
              <div className="flex-1 flex items-center justify-start ml-0">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/"><img
                    className="block h-8 min-[1380px]:h-10 w-auto px-3 md:px-0"
                    src={Logo}
                    alt="HolaDev"
                  /></Link>
                </div>
              </div>
              <div className="flex mr-1 min-[900px]:mr-0 sm:static sm:inset-auto sm:ml-6 sm:pr-0 self-start min-[900px]:self-auto mt-8 min-[900px]:mt-0">
                <div className="hidden min-[900px]:block sm:ml-6">
                  {navigation.map((item) =>
                    item.type === 'button' ? (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          isCurrentPathActive(item.href) ? 'btn-primary-variation' : 'btn-primary',
                          'btn btn-large min-[1380px]:btn-xlarge ml-3 lg:ml-5 mr-0 text-center'
                        )}
                        aria-current={isCurrentPathActive(item.href) ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          isCurrentPathActive(item.href) ? 'active' : 'hover:text-primary-light',
                          'navlink px-1.5 min-[1380px]:px-6 py-2 rounded-xl text-sm min-[1380px]:text-base font-semibold'
                        )}
                        aria-current={isCurrentPathActive(item.href) ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                </div>
                <div className="flex flex-col min-[900px]:hidden">
                  <div className="flex items-center self-end">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-neutral focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6 stroke-primary-dark" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6 stroke-primary-dark" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  <Transition
                    show={open}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Disclosure.Panel className="min-[900px]:hidden self-end">
                      <div className="px-4 pt-2 pb-3 space-y-1 bg-white rounded-md">
                        {navigation.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className={classNames(
                              isCurrentPathActive(item.href) ? 'active' : 'hover:text-primary-light',
                              'block px-3 py-2 rounded-md text-base font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
