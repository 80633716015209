exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about_us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookies-policy-jsx": () => import("./../../../src/pages/cookies-policy.jsx" /* webpackChunkName: "component---src-pages-cookies-policy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insights-jsx": () => import("./../../../src/pages/insights.jsx" /* webpackChunkName: "component---src-pages-insights-jsx" */),
  "component---src-pages-our-expertise-jsx": () => import("./../../../src/pages/our_expertise.jsx" /* webpackChunkName: "component---src-pages-our-expertise-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-why-holadev-jsx": () => import("./../../../src/pages/why_holadev.jsx" /* webpackChunkName: "component---src-pages-why-holadev-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

